<template>
  <div class="tips_box">
    <div class="tips_content"
         v-html="content">
      {{content}}
    </div>
    <p class="tips">{{$t('toast.bannerGameTxt3')}}</p>
    <div class="tips_comfirm">{{$t('toast.comfirmBtn2')}}</div>
  </div>
</template>

<script>
import { agreement } from "@/api/init"
import configData from "@/utils/configData"
export default {
  data () {
    return {
      content: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const msg = Object.assign(JSON.parse(JSON.stringify(configData)), { type: 3 })
      agreement(msg).then(res => {
        this.content = this.place(res.data.content)
      })
    },
    place (data) {
      const content = data.replace(/<e>/g, '<div  style="width:100%;text-align:center;">').replace(/<\/e>/g, '</div>').replace(/<red>/g, '<span style="color:red;">').replace(/<\/red>/g, '</span>').replace(/<b>/g, '<stong style=" font-weight: 700;">').replace(/<\/b>/g, '</stong>')
        .replace(/\n/g, '</br>')
      return content
    }
  }
}
</script>

<style lang="scss" scope>
@media (min-width: 1600px) {
  .tips_box {
    .tips_title {
      font-size: 32px;
      font-weight: bold;
      color: #575757;
      text-align: center;
    }
    .tips_content {
      width: 100%;
      height: 300px;
      padding: 17px 50px;
      box-sizing: border-box;
      font-size: 26px;
      color: #575757;
      overflow-y: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        scrollbar-arrow-color: red;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
        scrollbar-arrow-color: red;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .tips {
      margin-top: 16px;
      text-align: center;
      color: #a8a8a8;
      font-size: 26px;
    }
    .tips_comfirm {
      width: 600px;
      height: 80px;
      background: #ff7391;
      opacity: 1;
      border-radius: 6px;
      margin: 0 auto;
      margin-top: 35px;
      font-size: 32px;
      color: #fff;
      text-align: center;
      line-height: 80px;
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .tips_box {
    .tips_title {
      font-size: 32 * 0.85px;
      font-weight: bold;
      color: #575757;
      text-align: center;
    }
    .tips_content {
      width: 100%;
      height: 300 * 0.85px;
      padding: 17 * 0.85px 50 * 0.85px;
      box-sizing: border-box;
      font-size: 26 * 0.85px;
      color: #575757;
      overflow-y: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        scrollbar-arrow-color: red;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
        scrollbar-arrow-color: red;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .tips {
      margin-top: 16 * 0.85px;
      text-align: center;
      color: #a8a8a8;
      font-size: 26 * 0.85px;
    }
    .tips_comfirm {
      width: 600 * 0.85px;
      height: 80 * 0.85px;
      background: #ff7391;
      opacity: 1;
      border-radius: 6 * 0.85px;
      margin: 0 auto;
      margin-top: 35 * 0.85px;
      font-size: 32 * 0.85px;
      color: #fff;
      text-align: center;
      line-height: 80 * 0.85px;
    }
  }
}
@media screen and (max-width:1199px) {
  .tips_box {
    .tips_title {
      font-size: 32 * 0.7px;
      font-weight: bold;
      color: #575757;
      text-align: center;
    }
    .tips_content {
      width: 100%;
      height: 300 * 0.7px;
      padding: 17 * 0.7px 50 * 0.7px;
      box-sizing: border-box;
      font-size: 26 * 0.7px;
      color: #575757;
      overflow-y: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        scrollbar-arrow-color: red;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
        scrollbar-arrow-color: red;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .tips {
      margin-top: 16 * 0.7px;
      text-align: center;
      color: #a8a8a8;
      font-size: 26 * 0.7px;
    }
    .tips_comfirm {
      width: 600 * 0.7px;
      height: 80 * 0.7px;
      background: #ff7391;
      opacity: 1;
      border-radius: 6 * 0.7px;
      margin: 0 auto;
      margin-top: 35 * 0.7px;
      font-size: 32 * 0.7px;
      color: #fff;
      text-align: center;
      line-height: 80 * 0.7px;
    }
  }
}
</style>
